<template>
  <div class="bg-body-dark text-body overflow-hidden px-4 py-6 md:p-6 lg:p-10 items-center flex min-h-screen justify-center">
    <main class="flex md:grid grid-cols-12 gap-12 lg:gap-20">
      <section class="grid col-span-12 md:col-span-6 relative z-10">
        <header>
          <a href="https://mailviews.com" class="select-none">
            <img src="img/mailviews.png" alt="Mailviews" width="220">
          </a>
        </header>
        <div class="md:hidden -mx-4 mt-10 mb-6">
          <img class="object-fill" src="img/preview-mobile.jpg" alt>
        </div>
        <article>
          <h1 class="text-2xl lg:text-4xl lg:leading-snug font-bold text-white mb-6 max-w-xl">Medium, rare, but mostly well-done HTML email components.</h1>
          <p class="text-lg lg:text-xl max-w-lg mb-8 lg:mb-10">
            Responsive, easy-to-use HTML email templates and components from the creators of
            <a href="https://maizzle.com" class="hover:text-indigo-100">Maizzle</a>.
          </p>
          <p
           v-show="!subscribed"
           class="text-lg lg:text-xl max-w-lg mb-8 lg:mb-10"
          >Get notified when we launch:</p>
          <form v-show="!subscribed" @submit.prevent="subscribe">
            <div
              class="md:grid grid-cols-12 items-center max-w-md md:py-2 mb-6 lg:mb-0 rounded-full md:bg-body-darker md:border-2 border-body-darker md:focus-within:border-body-light"
              :class="{'md:bg-opacity-50 border-opacity-5': busy}"
            >
              <div class="md:grid col-span-7 lg:col-span-8 md:px-6 lg:px-8 cursor-text">
                <label
                  class="text-xs text-white cursor-text"
                  :class="{'opacity-30': busy}"
                >
                  Email<span class="md:hidden">:</span>
                  <input
                    v-model="email"
                    type="email"
                    required
                    :disabled="busy"
                    class="w-full py-2 md:p-0 bg-transparent text-lg placeholder-gray-500 focus:placeholder-gray-400 border-b border-transparent focus:border-teal-300 md:border-none focus:outline-none"
                    placeholder="user@example.com"
                  >
                </label>
              </div>
              <div class="hidden md:grid col-span-5 lg:col-span-4 justify-self-end px-2">
                <button
                  type="submit"
                  :disabled="busy"
                  class="md:px-3 lg:px-5 md:py-2 lg:py-2.5 rounded-full font-bold text-white bg-teal-400 hover:bg-teal-500 focus:outline-none select-none"
                >{{busy ? 'Subscribing...' : 'Notify me'}}</button>
              </div>
            </div>
            <button
              type="submit"
              :disabled="busy"
              class="md:hidden px-4 py-2 rounded-full font-bold text-white bg-teal-400 hover:bg-teal-500 focus:outline-none"
            >{{busy ? 'Subscribing...' : 'Notify me'}}</button>
          </form>
          <p
            v-if="subscribed"
            class="text-lg lg:text-xl max-w-lg mb-8 md:mb-0"
          >
            Awesome, you're on the list!
            <br><br>
            We're working hard to launch later this year, so we'll get in touch once we have more to share. Thanks!
            <br><br>
            Meanwhile, you can also follow <a href="https://twitter.com/mailviewsco" class="hover:text-indigo-100">@mailviewsco</a> on Twitter ;)
          </p>
          <p
            v-if="error.message"
            class="text-lg lg:text-xl mt-8 text-red-400 max-w-lg"
            v-html="error.message"
          />
        </article>
        <footer class="grid items-end mt-8 md:mt-0">
          <p class="text-base">
            &copy; {{ year }} Mailviews.
            Crafted with
            <a href="https://tailwindcss.com" class="hover:text-indigo-100">Tailwind CSS</a>
            in
            <a href="https://maizzle.com" class="hover:text-indigo-100">Maizzle</a>.
          </p>
        </footer>
      </section>
      <aside class="hidden col-span-12 md:col-span-6 md:order-first md:justify-end md:flex relative z-10">
        <img class="rounded-xl select-none md:w-580 object-cover" src="img/preview.jpg" alt>
      </aside>
    </main>
    <img src="img/backdrop.svg" alt class="fixed bottom-0 right-0 select-none pointer-events-none">
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      busy: false,
      error: {
        code: null,
        message: null,
      },
      subscribed: false,
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
  },
  methods: {
    subscribe() {
      const $vm = this

      $vm.busy = true
      $vm.error.code = null
      $vm.error.message = null

      fetch(`/.netlify/functions/subscribe`, {
        method: 'POST',
        body: $vm.email
      })
      .then(response => response.json())
      .then(response => {
        if (!response.success) {
          $vm.error.code = response.error.code
          $vm.error.message = response.error.message
        } else {
          $vm.subscribed = true
        }
      })
      .catch(error => {
        $vm.error.code = error.statusCode
        $vm.error.message = error.message
      })
      .finally(() => {
        $vm.busy = false
      })
    },
  },
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
